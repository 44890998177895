import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";
import Header from "../components/header";
import SearchResults from "../components/searchResults";
import Footer from "../components/footer";
import SeO from "../components/SEO";
import NotFoundPage from "./404";
import url from "../helper/url";
import settings from "../components/settings.json";

const SearchCategory = ({ data }) => {
  const [slug, setSlug] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState();

  useEffect(() => {
    if (data.serverData.data.searchByCatOrBusinessSlug.length >= 0) {
      setSlug(
        data.serverData.data.searchByCatOrBusinessSlug.length > 0
          ? data.serverData.data.searchByCatOrBusinessSlug[0]?.suggestiontext?.replaceAll(
              /&|-/g,
              " "
            )
          : data.slug?.replaceAll(/&|-/g, " ")
      );

      setPageTitle(
        data.serverData.data.searchByCatOrBusinessSlug[0]?.suggestiontext
      );
    } else {
      setPageTitle("Not Found");
    }
  }, [data]);

  const distanceArray = data.serverData.data.searchByCatOrBusinessSlug;
  const slugName =
    data?.serverData?.data.searchByCatOrBusinessSlug[0]?.suggestiontext?.replaceAll(
      /&|- /g,
      ""
    );
  if (data.city) {
    return distanceArray.length > 0 ? (
      <div>
        <SeO
          title={pageTitle || data.slug.replaceAll("-", " ")}
          description={`This is list businesses in ${
            data.serverData.data.searchByCatOrBusinessSlug[0].suggestiontext
          } category at ${data.city}, ${
            data.stateCode
          } register with christian blue. Suggestion related to ${
            data.serverData.data.searchByCatOrBusinessSlug[0].suggestiontext
          } are ${data.serverData.data.searchByCatOrBusinessSlug[0].suggestionrelated.replace(
            /\([^()]*\);/g,
            ""
          )}`}
        />
        <Header
          path={data.path}
          city={`${data.city}-${data.stateCode}`}
          searchSlug={slugName || data.slug}
        />
        <SearchResults
          location={data?.city}
          stateCode={data?.stateCode}
          slug={slug}
          name={data.slug.replaceAll("-", " ")}
          loading={loading}
          data={data.serverData.data.searchByCatOrBusinessSlug}
        />

        <Footer />
      </div>
    ) : (
      <NotFoundPage />
    );
  } else {
    return (
      <div>
        <SeO
          title={pageTitle || data.slug.replaceAll("-", " ")}
          description={`This is list businesses in ${
            data.serverData.data.searchByCatOrBusinessSlug[0].suggestiontext
          } category at ${data.city}, ${
            data.stateCode
          } register with christian blue. Suggestion related to ${
            data.serverData.data.searchByCatOrBusinessSlug[0].suggestiontext
          } are ${data.serverData.data.searchByCatOrBusinessSlug[0].suggestionrelated.replace(
            /\([^()]*\);/g,
            ""
          )}`}
        />
        <Header
          path={data.path}
          city={`${data.city}-${data.stateCode}`}
          searchSlug={slugName || data.slug}
        />
        <SearchResults
          location={data?.city}
          stateCode={data?.stateCode}
          slug={slug}
          name={data.slug.replaceAll("-", " ")}
          loading={loading}
          data={data.serverData.data.searchByCatOrBusinessSlug}
        />

        <Footer />
      </div>
    );
  }
};

const SearchSubCategory = ({ data, error }) => {
  const [slug, setSlug] = useState("");

  const [loading, setLoading] = useState(false);

  const [pageTitle, setPageTitle] = useState();

  useEffect(() => {
    if (data?.serverData.data.searchBySubcategory.length >= 0) {
      setSlug(
        data?.serverData.data.searchBySubcategory.length > 0
          ? data.serverData.data.searchBySubcategory[0].suggestiontext?.replaceAll(
              /&|-/g,
              " "
            )
          : data.slug?.replaceAll(/&|-/g, " ")
      );

      setPageTitle(
        data?.serverData?.data.searchBySubcategory[0]?.suggestiontext
      );
    } else {
      setPageTitle("Not Found");
    }
  }, []);

  if (error) {
    return <h3>Error</h3>;
  }

  const distanceArray = data?.serverData.data.searchBySubcategory.filter(
    (item) => item.distance !== 0
  );
  const slugName =
    data.serverData.data.searchBySubcategory[0].suggestiontext?.replaceAll(
      /&|- /g,
      ""
    );
  if (data.city) {
    return distanceArray.length > 0 ? (
      <div>
        <SeO
          title={pageTitle || data.slug.replaceAll("-", " ")}
          description={`This is list businesses in ${
            data.serverData.data.searchBySubcategory[0].suggestiontext
          } category at ${data.city}, ${
            data.stateCode
          } register with christian blue. Suggestion related to ${
            data.serverData.data.searchBySubcategory[0].suggestiontext
          } are ${data.serverData.data.searchBySubcategory[0].suggestionrelated.replace(
            /\([^()]*\);/g,
            ""
          )}`}
        />
        <Header
          path={data.path}
          city={`${data.city}-${data.stateCode}`}
          searchSlug={slugName || data.slug}
        />
        <SearchResults
          location={data?.city}
          stateCode={data?.stateCode}
          slug={slug}
          name={data?.slug.replaceAll("-", " ")}
          loading={loading}
          data={data?.serverData.data.searchBySubcategory}
        />

        <Footer />
      </div>
    ) : (
      <NotFoundPage />
    );
  } else {
    return (
      <div>
        <SeO
          title={pageTitle || data.slug.replaceAll("-", " ")}
          description={`This is list businesses in ${
            data.serverData.data.searchBySubcategory[0].suggestiontext
          } category at ${data.city}, ${
            data.stateCode
          } register with christian blue. Suggestion related to ${
            data.serverData.data.searchBySubcategory[0].suggestiontext
          } are ${data.serverData.data.searchBySubcategory[0].suggestionrelated.replace(
            /\([^()]*\);/g,
            ""
          )}`}
        />
        <Header
          path={data.path}
          city={`${data.city}-${data.stateCode}`}
          searchSlug={slugName || data.slug}
        />
        <SearchResults
          location={data?.city}
          stateCode={data?.stateCode}
          slug={slug}
          name={data?.slug.replaceAll("-", " ")}
          loading={loading}
          data={data.serverData.data.searchBySubcategory}
        />

        <Footer />
      </div>
    );
  }
};

const c = ({ pageContext, serverData }) => {
  return (
    <Router>
      <SearchCategory
        path="/c/:slug/:cityState?:lat-long"
        data={serverData}
        context={pageContext}
      />
      <SearchCategory path="/c/:slug" data={serverData} context={pageContext} />
      <SearchSubCategory
        path="c/:slug/subcat/:subcat"
        data={serverData}
        context={pageContext}
      />
      <SearchSubCategory
        path="c/:slug/subcat/:subcat/:cityState?:lat-long"
        data={serverData}
        context={pageContext}
      />

      <NotFoundPage path="/c/*" />
    </Router>
  );
};
export default c;

export async function getServerData(props) {
  const { slug, subCat, city, stateCode,lat,long } = url(props);

  try {
    if (subCat) {
      if (city && stateCode) {
        const res = await fetch(settings.apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
                searchBySubcategory(
                  location: "${city}"
                  slug: "${subCat}"
                  state_code: "${stateCode}"
                ) {
                  id
                  others
                  suggestiontext
                  suggestionrelated
                  suggestionurls
                  status
                  certified
                  name
                  phone
                  address
                  city
                  state
                  zip
                  message
                  referred_by
                  category
                  company_logo
                  latitude
                  statusmessage
                  longitude
                  website
                  facebook
                  instagram
                  youtube
                  twitter
                  description
                  service_frequency
                  days_open
                  email
                  images {
                    id
                    path
                  }
                  company_coupon
                  company_ad
                  advertise_begin_date
                  review_link
                  spotlight_link
                  audio
                  video
                  category
                  wal_pdf
                  keywords
                  distance
                  linkedin
                  show_address
                  show_ad
                  company_ad
                  slug
                }
            }`,
          }),
        });
        if (!res.ok) {
          throw new Error(`Response failed`);
        }

        const data = {
          serverData: await res.json(),
          slug: subCat,
          city: city,
          stateCode: stateCode,
          path: props.url.split("/")[2],
        };

        return {
          props: data,
        };
      } else {
        const res = await fetch(settings.apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
                searchBySubcategory(
                
                  slug: "${subCat}"
                
                ) {
                  id
                  others
                  suggestiontext
                  suggestionrelated
                  suggestionurls
                  status
                  certified
                  name
                  phone
                  address
                  city
                  state
                  zip
                  message
                  referred_by
                  category
                  company_logo
                  latitude
                  statusmessage
                  longitude
                  website
                  facebook
                  instagram
                  youtube
                  twitter
                  description
                  service_frequency
                  days_open
                  email
                  images {
                    id
                    path
                  }
                  company_coupon
                  company_ad
                  advertise_begin_date
                  review_link
                  spotlight_link
                  audio
                  video
                  category
                  wal_pdf
                  keywords
                  distance
                  linkedin
                  show_address
                  show_ad
                  company_ad
                  slug
                }
              }`,
          }),
        });
        if (!res.ok) {
          throw new Error(`Response failed`);
        }

        const data = {
          serverData: await res.json(),
          slug: subCat,
          city: city,
          path: props.url.split("/")[2],
        };

        return {
          props: data,
        };
      }
    } else {
      if (city) {
        const res = await fetch(settings.apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
            searchByCatOrBusinessSlug(
              slug: "${slug}"
              state_code: "${stateCode}"
              location: "${city}"
              latitude: "${lat}"
              longitude: "${long}"
            ) {
              id
              others
              suggestiontext
              suggestionrelated
              suggestionurls
              status
              certified
              name
              phone
              address
              city
              state
              zip
              message
              referred_by
              category
              company_logo
              latitude
              statusmessage
              longitude
              website
              facebook
              instagram
              youtube
              twitter
              description
              service_frequency
              days_open
              email
              images {
                id
                path
              }
              company_coupon
              company_ad
              advertise_begin_date
              review_link
              spotlight_link
              audio
              video
              category
              wal_pdf
              keywords
              distance
              linkedin
              show_address
              show_ad
              company_ad
              slug
            }
            }`,
          }),
        });
        if (!res.ok) {
          throw new Error(`Response failed`);
        }

        const data = {
          serverData: await res.json(),
          slug: slug,
          city: city,
          stateCode: stateCode,
          path: props.url.split("/")[2],
        };

        return {
          props: data,
        };
      } else {
        const res = await fetch(settings.apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
              searchByCatOrBusinessSlug(
                slug: "${slug}"
             
              ) {
                id
                others
                suggestiontext
                suggestionrelated
                suggestionurls
                status
                certified
                name
                phone
                address
                city
                state
                zip
                message
                referred_by
                category
                company_logo
                latitude
                statusmessage
                longitude
                website
                facebook
                instagram
                youtube
                twitter
                description
                service_frequency
                days_open
                email
                images {
                  id
                  path
                }
                company_coupon
                company_ad
                advertise_begin_date
                review_link
                spotlight_link
                audio
                video
                category
                wal_pdf
                keywords
                distance
                linkedin
                show_address
                show_ad
                company_ad
                slug
              }
              }`,
          }),
        });
        if (!res.ok) {
          throw new Error(`Response failed`);
        }

        const data = {
          serverData: await res.json(),
          slug: slug,
          city: city,
          path: props.url.split("/")[2],
        };

        return {
          props: data,
        };
      }
    }
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}
