/* eslint no-use-before-define: 0 */
import React, { useState } from "react";
import NoResult from "./noresult";
import Ads from "./ads";
import BusinessCard from "./businesscard";
import Loader from "../images/loader.gif";
import { Link } from "gatsby";
import Pagination from "@material-ui/lab/Pagination";
import scrollToSection from "./scroller";

export default function SearchResults({
  name,
  loading,
  data,
  location,
  stateCode,
}) {
  const [page, setPage] = useState(1);

  if (!loading && data.length === 0) {
    return <NoResult search={name} loading={loading} />;
  } else if (loading) {
    return (
      <div className="search-result-section">
        <Ads zoneid={78} />
        <section className="container main-category">
          <div className="row">
            <div className="col-md-12">
              {loading && (
                <p className="loader-wrapper">
                  <img
                    height="100px"
                    alt="Loader"
                    width="100px"
                    style={{ margin: 30 }}
                    src={Loader}
                  />
                </p>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div className="search-result-section">
        <Ads zoneid={78} />
        <section className="container main-category">
          <div className="row">
            <div className="col-md-12">
              {name ? (
                <p className="suggestions">Searching for "{name}"</p>
              ) : (
                ""
              )}
              {data[0].suggestionurls !== "" ? (
                <p className="suggestions">Matched Suggestions</p>
              ) : (
                ""
              )}
              {data[0].suggestionrelated !== ""
                ? data[0].suggestionrelated.split(";").map((name, index) => {
                    if (data[0].suggestionurls !== "") {
                      var urls = data[0].suggestionurls.split(";");
                    }
                    if (
                      index ===
                      data[0].suggestionurls.split(";").length - 1
                    ) {
                      return (
                        <Link
                          key={"suggestionLink" + index}
                          className="suggestionLinks"
                          to={
                            location && stateCode
                              ? urls[parseInt(index)] +
                                "/" +
                                location +
                                "-" +
                                stateCode
                              : urls[parseInt(index)]
                          }
                        >
                          {name}
                        </Link>
                      );
                    } else {
                      return (
                        <>
                          <Link
                            key={"suggestionLink" + index}
                            className="suggestionLinks"
                            to={
                              location && stateCode
                                ? urls[parseInt(index)] +
                                  "/" +
                                  location +
                                  "-" +
                                  stateCode
                                : urls[parseInt(index)]
                            }
                          >
                            {name}
                          </Link>
                          <span
                            key={"suggestionSeparator" + index}
                            className="suggestionSeparator"
                          >
                            ,{" "}
                          </span>
                        </>
                      );
                    }
                  })
                : ""}

              {data.length !== 0 ? (
                <p
                  style={{
                    paddingTop: 15,
                    paddingBottom: 20,
                    fontSize: 20,
                    fontWeight: `bold`,
                  }}
                >
                  {data.length} result{data.length > 1 && "s"}
                </p>
              ) : (
                ""
              )}

              {data.length > 20 && (
                <h5 className="pt-1 pb-3 font-weight-bold">
                  Showing {20 * (page - 1) + 1} to{" "}
                  {20 * page > data.length ? data.length : 20 * page}
                </h5>
              )}

              {data.slice(20 * (page - 1), 20 * page).map((item, index) => {
                if (index !== 0 && index % 3 === 0) {
                  return (
                    <div key={"ads1" + index} className="searchAdv searchAdv78">
                      <Ads zoneid={78} marginAdv={"0 7%"} />
                      <BusinessCard
                        data={item}
                        location={location}
                        stateCode={stateCode}
                      />
                    </div>
                  );
                } else {
                  return (
                    <BusinessCard
                      data={item}
                      location={location}
                      stateCode={stateCode}
                      key={"businessCard" + index}
                    />
                  );
                }
              })}
            </div>
          </div>

          {data.length > 20 && (
            <Pagination
              count={Math.ceil(data.length / 20)}
              page={page}
              showFirstButton
              showLastButton
              onChange={(e, val) => {
                scrollToSection("search-result-section");
                setPage(val);
              }}
            />
          )}
        </section>
      </div>
    );
  }
}
