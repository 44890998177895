import React from "react";
// import Empty from "../images/empty.jpg"
import Ads from "../components/ads";
// import Loader from "../images/loader.gif"
import { Link } from "gatsby";

export default function NotFound(display) {
  return (
    <div className="search-result-section">
      <Ads zoneid={79} />
      <p
        className="blocktext mt-4"
        style={{ marginBottom: `48`, marginLeft: `auto`, marginRight: `auto` }}
      >
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
      </p>

      <div>
        <section className="container main-category">
          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-primary text-center mt-3">
                Would you like to{" "}
                <Link
                  id="noResultAnchor"
                  to="/categories/"
                  style={{ textDecoration: "none" }}
                >
                  expand your search
                </Link>
                ?
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
