import React from 'react'
import SeO from "../components/SEO"
import Header from "../components/header"
import NotFound from "../components/notfound"
import Footer from "../components/footer"

const NotFoundPage = () => {
  return (
    <div>
      <SeO title={`Not Found`} defer={true} />
      <Header />
      <NotFound />
      <Footer />
    </div>
  )
}

export default NotFoundPage